import { API } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useState } from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function Subscribe() {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = (event, reason) => {
    setOpen(true);
  };
  const subscribe = () => {
    if (authStatus !== "authenticated" && !user) {
      return;
    }
    console.log("subscribe");
    const apiName = "api";
    const myInit = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        // "Content-Type": "application/json",
        Authorization: user.signInUserSession.accessToken.jwtToken,
      },
      body: JSON.stringify({
        name: user.username,
        email: user.attributes.email,
        success_url: "https://chatgpros.com",
      }),
    };
    API.post(apiName, "payment/create-checkout-session", myInit)
      .then((response) => {
        console.log("response", response);
        if (response.url) {
          window.location.href = response.url;
        }
      })
      .catch((error) => {
        console.log("error with payment");
        console.log("er", error);
      });
  };
  return (
    <Modal
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          You have used all of your free messages
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Subscribe now to get unlimited access to the chat bot.
        </Typography>
        <Button onClick={subscribe}>Subscribe</Button>
        <Button
          onClick={() => {
            signOut();
          }}
        >
          Sign Out
        </Button>
      </Box>
    </Modal>
  );
}
export default Subscribe;
