import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ListItemButton from "@mui/material/ListItemButton";
import { useUpdateSession } from "../../provider/SessionProvider/SessionProvider";
function SessionItem({ conversation }) {
  const updateSession = useUpdateSession();
  const handleClick = () => {
    console.log("clicked", conversation);
    updateSession(conversation);
  };
  return (
    <ListItem key={conversation.id} disablePadding onClick={handleClick}>
      <ListItemButton>
        <ListItemIcon>
          <ChatBubbleIcon />
        </ListItemIcon>
        <ListItemText primary={conversation.short_title} />
      </ListItemButton>
    </ListItem>
  );
}

export default SessionItem;
