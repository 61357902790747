import React from "react";
import ReactMarkdown from "react-markdown";

const About = () => {
  const markdown = `
  ## About

  This website harnesses the power of state-of-the-art AI generative language models, such as ChatGPT-3, to provide you with a wealth of information, interactive discussions, and engaging conversations. Our goal is to create an accessible platform where you can explore a wide range of topics, seek answers to your questions, and engage with AI in a conversational manner.

### Warnings:

Accuracy and Reliability: While our AI models strive to provide accurate and reliable information, it's important to remember that they are trained on vast amounts of data and may not always produce completely accurate or up-to-date responses. Use the information provided with caution and cross-reference it with reliable sources.

Lack of Context and Common Sense: AI language models operate based on patterns and probabilities derived from training data. They lack real-world experiences, context, and common sense reasoning. Consequently, their responses might be nonsensical or provide incomplete information. Exercise critical thinking and verify information from multiple sources.

Biases and Prejudices: AI models learn from the data they are trained on, which can include biased or prejudiced content present in the training data. As a result, the generated responses may inadvertently reflect these biases. Be mindful of this and critically evaluate the information presented.

Inappropriate or Offensive Content: AI models can sometimes generate inappropriate or offensive content. Despite efforts to filter such responses, there is a possibility of encountering offensive language, hate speech, or other objectionable material. We encourage users to report any such instances to help us continually improve our system.

### Highlights:

Interactive Conversations: Engage in dynamic conversations with our AI language model. Ask questions, seek advice, and explore various topics in a conversational manner.

Broad Knowledge Base: Our AI model has been trained on an extensive corpus of information, allowing it to provide insights on diverse subjects, ranging from science and technology to history, arts, and more.

Language Support: ChatGPT-3 supports multiple languages, enabling users from around the world to communicate and seek information in their native language.

Personalized Experience: Over time, the AI model learns from user interactions and adapts to provide more tailored responses. This helps create a personalized experience and enhances the quality of conversations.

Continuous Learning: Our AI system is regularly updated to improve its performance, address limitations, and incorporate user feedback. This ensures that you benefit from the latest advancements in AI technology.

Remember, while AI language models like ChatGPT-3 are powerful tools, they have inherent limitations and potential risks. It is crucial to exercise critical thinking, verify information from reliable sources, and be aware of the biases and limitations of the technology.

`;

  return <ReactMarkdown children={markdown} />;
};

export default About;
