import React, { useState } from "react";
import SessionItem from "./MenuItem";
import Drawer from "@mui/material/Drawer";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import "./SideMenu.css";
import {
  useResetSession,
  useSession,
} from "../../provider/SessionProvider/SessionProvider";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import { useUpdatePage } from "../../provider/PageProvider/PageProvider";
// import { signOut } from "../../provider/AuthProvider/GetAuth";
function SideMenu() {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  const drawerWidth = 240;
  const resetSession = useResetSession();
  const session = useSession();
  const [conversations, setConversations] = useState([]);
  const updatePage = useUpdatePage();
  React.useEffect(() => {
    if (session && authStatus === "authenticated" && user) {
      const apiName = "api";
      const myInit = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: user.signInUserSession.accessToken.jwtToken,
        },
      };
      API.get(apiName, "api/conversations", myInit)
        .then((response) => {
          setConversations(response.conversations);
        })

        .catch((error) => {
          console.log("er", error.response);
        });
    }
  }, [session]);
  return (
    <div className="side-menu">
      <Drawer
        sx={{
          width: drawerWidth,
          height: "100vh-64px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Button onClick={resetSession}>
          <AddIcon />
          New Chat
        </Button>

        <Divider />

        <List>
          {conversations.map((conversation, index) => (
            <SessionItem conversation={conversation} />
          ))}
        </List>

        <Button onClick={() => updatePage("chat")}>Chat</Button>
        {/* <Button onClick={() => updatePage("account")}>My Account</Button> */}
        <Button
          onClick={() => {
            signOut();
          }}
        >
          Sign Out
        </Button>
        <Button onClick={() => updatePage("about")}>About</Button>
      </Drawer>
    </div>
  );
}

export default SideMenu;
