import React from "react";

const PageContext = React.createContext(null);
const UpdatePageContext = React.createContext(null);
// export const DataProvider = DataContext.Provider;
export default PageContext;

export const PageProvider = ({ children }) => {
  const [page, setPage] = React.useState("chat");
  function updatePage(newPage) {
    setPage(newPage);
    console.log("update", newPage);
  }
  return (
    <PageContext.Provider value={page}>
      <UpdatePageContext.Provider value={updatePage}>
        {children}
      </UpdatePageContext.Provider>
    </PageContext.Provider>
  );
};
export function usePage() {
  return React.useContext(PageContext);
}
export function useUpdatePage() {
  return React.useContext(UpdatePageContext);
}
