import React from "react";

const SessionContext = React.createContext(null);
const UpdateSessionContext = React.createContext(null);
// export const DataProvider = DataContext.Provider;
export default SessionContext;

export const SessionProvider = ({ children }) => {
  console.log("session provider");
  const [session, setSession] = React.useState({ session: null, history: [] });
  function updateSession(newSession) {
    setSession(newSession);
    console.log("update", newSession);
  }
  return (
    <SessionContext.Provider value={session}>
      <UpdateSessionContext.Provider value={updateSession}>
        {children}
      </UpdateSessionContext.Provider>
    </SessionContext.Provider>
  );
};
export function useSession() {
  return React.useContext(SessionContext);
}
export function useUpdateSession() {
  return React.useContext(UpdateSessionContext);
}
export function useResetSession() {
  const updateSession = useUpdateSession();
  return () => updateSession({ session: null, history: [] });
}
