import React from "react";
import ReactMarkdown from "react-markdown";
import { motion } from "framer-motion";

import "./ChatMessage.css";

const ChatMessageTypingIndicator = () => {
  const sentence = {
    hidden: { opacity: 1 },
    visible: { opacity: 1, transition: { delay: 0.5, staggerChildren: 0.3 } },
  };
  const letter = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  const text = ". . . . . . . . . . . . . . . . . . ";
  return (
    <div className={`chat__message`}>
      <motion.p
        variants={sentence}
        initial="hidden"
        animate="visible"
        transition={{ repeat: "Infinity" }}
      >
        {text.split("").map((char, index) => {
          return (
            <motion.span key={char + "-" + index} variants={letter}>
              {char}
            </motion.span>
          );
        })}
      </motion.p>
    </div>
  );
};

export default ChatMessageTypingIndicator;
