import "./App.css";

import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { MultiProvider } from "./provider/MultiProvider/MultiProvider";
import { SessionProvider } from "./provider/SessionProvider/SessionProvider";
import { PageProvider } from "./provider/PageProvider/PageProvider";
import Main from "./components/Main/Main";
import ReactGA from "react-ga4";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
ReactGA.initialize("G-Z4TYH9V4DJ");

Amplify.configure(awsconfig);

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "api",
        endpoint: "https://api.chatgpros.com/",
        paths: ["/"],
      },
    ],
  },
});

function App() {
  return (
    <MultiProvider
      providers={[
        <SessionProvider />,
        <Authenticator.Provider />,
        <PageProvider />,
      ]}
    >
      <Main />
    </MultiProvider>
  );
}

export default App;
