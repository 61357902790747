import ChatInterface from "../ChatInterface/ChatInterface";
import SideMenu from "../SideMenu/SideMenu";
import Login from "../Login/Login";
import { Box } from "@mui/material";
import "./Main.css";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import { useState } from "react";
import Subscribe from "../Subscribe/Subscribe";
import { ThreeDots } from "react-loader-spinner";
import { usePage } from "../../provider/PageProvider/PageProvider";
import Account from "../Account/Account";
import About from "../About/About";
import TandC from "../TandC/TandC";
import Pdf from "../TandC/tandc.pdf";
import { useUpdatePage } from "../../provider/PageProvider/PageProvider";
function Main() {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const [tancVisible, setTandCVisible] = useState(false);
  const page = usePage();
  const [hasAccess, setHasAccess] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const updatePage = useUpdatePage();

  console.log(user);
  console.log(authStatus);
  if (authStatus == "authenticated" && user) {
    const checkAccess = () => {
      const apiName = "api";
      const path = "/api/userdetails";
      const myInit = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: user.signInUserSession.accessToken.jwtToken,
        },
      };
      API.get(apiName, path, myInit)
        .then((response) => {
          setHasAccess(response.user_details.hasAccess);
          setLoaded(true);
          console.log(loaded);
          return response;
        })
        .catch((error) => {
          console.log("er", error);
        });
    };
    checkAccess();
    const toggleTandC = () => {
      console.log("toggle");

      updatePage("tandc");
    };
    if (loaded == false) {
      return (
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#3f51b5"
          ariaLabel="three-dots-loading"
          wrapperStyle={{ justifyContent: "center", alignItems: "center" }}
          wrapperClassName=""
          visible={true}
        />
      );
    }
    return (
      <Box sx={{ display: "flex" }}>
        <SideMenu />
        {!hasAccess && <Subscribe />}
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          {page === "chat" && <ChatInterface />}
          {page === "about" && <About />}
          {page === "account" && <Account />}
          <div className="footer" align="center">
            <a href={Pdf} target="_blank">
              Terms and conditions
            </a>
          </div>
          {page === "tandc" && <TandC />}
        </Box>
      </Box>
    );
  } else {
    return <Login />;
  }
}
export default Main;
