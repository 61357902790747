import React from "react";
import ReactMarkdown from "react-markdown";
import { motion } from "framer-motion";

import "./ChatMessage.css";

const ChatMessage = ({ text, sender }) => {
  const messageClass = sender === "user" ? "chat__message-own" : "";

  return (
    <div className={`chat__message ${messageClass}`}>
      <ReactMarkdown children={text} />
    </div>
  );
};
//   );
//   } else {
//     const sentence = {
//       hidden: { opacity: 1 },
//       visible: { opacity: 1, transition: { delay: 10, staggerChildren: 0.03 } },
//     };
//     const letter = {
//       hidden: { opacity: 0, y: 50 },
//       visible: { opacity: 1, y: 0 },
//     };

//     return (
//       <div className={`chat__message ${messageClass}`}>
//         <motion.p variants={sentence} initial="hidden" animate="visible">
//           {text.split("").map((char, index) => {
//             return (
//               <motion.span key={char + "-" + index} variants={letter}>
//                 {char}
//               </motion.span>
//             );
//           })}
//         </motion.p>
//       </div>
//     );
//   }
// };

export default ChatMessage;
