import React, { useEffect, useState } from "react";
import ChatMessage from "../ChatMessage/ChatMessage";
import ChatMessageTypingIndicator from "../ChatMessage/ChatMessageTypingIndicator";
import "./ChatInterface.css";
import {
  useSession,
  useUpdateSession,
} from "../../provider/SessionProvider/SessionProvider";
import { Button } from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";

function ChatInterface() {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const [inputValue, setInputValue] = useState("");
  const [thinking, setThinking] = useState(false);
  const [hasAccess, setHasAccess] = useState(true);
  const [portalUrl, setPortalUrl] = useState("");
  const session = useSession();
  console.log(session);
  const updateSession = useUpdateSession();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const checkAccess = () => {
    if (authStatus !== "authenticated" && !user) {
      return;
    }
    const apiName = "api";
    const path = "/api/userdetails";
    const myInit = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: user.signInUserSession.accessToken.jwtToken,
      },
    };
    API.get(apiName, path, myInit)
      .then((response) => {
        setHasAccess(response.user_details.hasAccess);
        return response;
      })
      .catch((error) => {
        console.log("er", error);
      });
  };
  useEffect(() => {
    checkAccess();
  }, []);
  const conversation = () => {
    if (authStatus !== "authenticated" && !user) {
      return;
    }
    const apiName = "api";
    const myInit = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: user.signInUserSession.accessToken.jwtToken,
      },
      body: JSON.stringify({ message: inputValue, session: session.session }),
    };
    API.post(apiName, "api/conversation", myInit)
      .then((response) => {
        return response;
      })
      .then((data) => {
        updateSession(data);
        setThinking(false);
        return data;
      })

      .catch((error) => {
        console.log("er", error);
      });
  };
  const subscribe = () => {
    if (authStatus !== "authenticated" && !user) {
      return;
    }
    const apiName = "api";
    const myInit = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: user.signInUserSession.accessToken.jwtToken,
      },
      body: JSON.stringify({
        name: user.username,
        email: user.attributes.email,
        success_url: "https://chatgpros.com",
      }),
    };
    API.post(apiName, "payment/create-checkout-session", myInit)
      .then((response) => {
        response.redirect(303, session.url);

        setPortalUrl(response.url);
        return response;
      })
      .then((data) => {
        updateSession(data);
        setThinking(false);
        return data;
      })

      .catch((error) => {
        console.log("er", error);
      });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    checkAccess();
    if (!hasAccess) {
      subscribe();
      console.log("no access redirect to portal");
      console.log("No access");
      console.log(session);
    } else {
      const history = [...session.history];
      history.push({ content: inputValue, role: "user" });
      conversation();
      session.history = history;
      updateSession(session);
      setThinking(true);
      setInputValue("");
    }
  };
  const regenerateReponse = () => {
    if (authStatus !== "authenticated" && !user) {
      return;
    }
    console.log(session);
    const apiName = "api";
    const myInit = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: user.signInUserSession.accessToken.jwtToken,
      },
      body: JSON.stringify({ session: session.session }),
    };
    // const history = [...session.history];
    // history.pop();
    // session.history = /history;
    updateSession(session);
    setThinking(true);
    API.post(apiName, "api/regenerate", myInit)
      .then((response) => {
        return response;
      })
      .then((data) => {
        updateSession(data);
        setThinking(false);
        return data;
      })

      .catch((error) => {
        console.log("er", error);
      });
  };

  return (
    <div className="full-width">
      <div className="chat">
        <div className="chat__wrapper">
          {session.history &&
            session.history.map((message, index) => (
              <ChatMessage
                key={index}
                text={message.content}
                sender={message.role}
              />
            ))}
          {thinking && <ChatMessageTypingIndicator />}
        </div>
      </div>
      {session.history && session.history.length > 0 && (
        <div className="chat__button">
          <Button onClick={regenerateReponse}>Regenerate response</Button>
        </div>
      )}
      <div className="chat__form">
        <form onSubmit={handleFormSubmit}>
          <input type="text" value={inputValue} onChange={handleInputChange} />
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
}

export default ChatInterface;
